import React from 'react';

import Image from 'next/image';

import { getRandomInt } from 'lib/utils/generateRandomInt';

export type CheckListprops = {
  description: string | JSX.Element | JSX.Element[];
};

const CheckList: React.FC<CheckListprops> = ({ description }) => {
  return (
    <>
      {Array.isArray(description) ? (
        <ul className="grid not-prose mb-6">
          {description.map((item, index) => {
            const idx = getRandomInt(1, 1000000);
            return (
              <li
                key={`${idx}-product-plan-card-whats-included`}
                className={[
                  'flex items-center gap-x-6 border-b-gray-300 border-b',
                  index === 0 ? 'pb-4 ' : 'py-4',
                ].join(' ')}
              >
                <Image src="/check-green.svg" width={18} height={18} alt="" />
                {item}
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className="grid not-prose mb-6">
          <li className="flex items-center gap-x-6 border-b-gray-300 border-b pb-5">
            <Image src="/check-green.svg" width={18} height={18} alt="" />
            {description}
          </li>
        </ul>
      )}
    </>
  );
};
export default CheckList;
