import React from 'react';
import { RichText, RichTextProps, Wrap } from '@leafwell/components';

import TextHighlight from './text-highlight';

export type WysiwygContentProps = Omit<RichTextProps, 'content'> & {
  content?: string;
  containerSize?: 'small' | 'default' | 'large';
  sectionId?: string;
  acfFcLayout?: string;
};

const WysiwygContent: React.FC<WysiwygContentProps> = ({
  content,
  containerSize,
  className,
  sectionId,
  ...rest
}) => {
  const Wrapper = containerSize ? Wrap : React.Fragment;
  const { acfFcLayout, ...params } = rest;

  return (
    <Wrapper
      {...(containerSize
        ? { size: containerSize, id: sectionId, ...params }
        : {})}
    >
      <RichText
        content={<TextHighlight text={content} />}
        className={[className].join(' ')}
        {...(containerSize ? {} : rest)}
      />
    </Wrapper>
  );
};

export default WysiwygContent;
